// createEmotionCache.ts
import createCache from '@emotion/cache'

const createEmotionCache = () => {
	return createCache({ key: 'cha' })
}

export const defaultCache = createEmotionCache()

export default createEmotionCache
