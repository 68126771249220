import { format, formatDistance } from 'date-fns'
import type { i18n } from 'i18next'

import { locales } from '../i18n/dates'

export const initFormatters = (i18next: i18n) => {
	i18next.services.formatter?.add('datetime', (value, lng = 'en', options) => {
		return format(value, options.fmt, {
			locale: locales[lng],
			...options
		})
	})

	i18next.services.formatter?.add(
		'relativetime',
		(value, lng = 'en', options) => {
			return formatDistance(value.laterDate, value.earlierDate, {
				locale: locales[lng],
				...options
			})
		}
	)

	i18next.services.formatter?.add('daterange', (value, lng = 'en', options) => {
		const from = format(value.from, options.fmt, {
			locale: locales[lng],
			...options
		})
		const to = format(value.to, options.fmt, {
			locale: locales[lng],
			...options
		})

		return i18next.dir(lng) === 'ltr' ? `${from} - ${to}` : `${to} - ${from}`
	})
}
