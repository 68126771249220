import React, { useState } from 'react'
import * as ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { HydratedRouter } from 'react-router/dom'

import { CacheProvider } from '@emotion/react'

import '@wwt/shared/i18n'

import { ClientStyleContext } from './context'
import createEmotionCache, { defaultCache } from './createEmotionCache'

if (import.meta.env.MODE !== 'development') {
	const tagManagerArgs = {
		gtmId: import.meta.env.VITE_GOOGLE_ANALYTICS_TAG_MANAGER
	}

	TagManager.initialize(tagManagerArgs)
}

interface ClientCacheProviderProps {
	children: React.ReactNode
}

// eslint-disable-next-line react-refresh/only-export-components
const ClientCacheProvider = ({ children }: ClientCacheProviderProps) => {
	const [cache, setCache] = useState(defaultCache)

	const reset = () => {
		setCache(createEmotionCache())
	}

	return (
		<ClientStyleContext.Provider value={{ reset }}>
			<CacheProvider value={cache}>{children}</CacheProvider>
		</ClientStyleContext.Provider>
	)
}

const hydrate = () => {
	React.startTransition(() => {
		ReactDOM.hydrateRoot(
			document,
			<ClientCacheProvider>
				<HydratedRouter />
			</ClientCacheProvider>
		)
	})
}

if (window.requestIdleCallback) {
	window.requestIdleCallback(hydrate)
} else {
	// Safari doesn't support requestIdleCallback
	// https://caniuse.com/requestidlecallback
	setTimeout(hydrate, 1)
}
